<template>
  <div>
    <div class="thanks-wrapper row justify-content-md-center pt-5">
      <div class="col-md-6 p-3" :class="$parent.isDesktop ? 'd-flex' : ''">
        <div class="col text-center">
          <img class="mt-3" src="https://tss-static-images.gumlet.io/thank-you/soul1.png" />
        </div>
        <div class="col">
          <h2 class="mt-3 app-title text-center"><b>Thanks for Submitting your Feedback</b></h2>
          <button @click="goBackToFeedback" class="btn btn-main mt-3 btn-block add-opacity pointer">Submit Another Feedback
          </button>
        </div>
      </div>
    </div> 
  </div>
</template>
<script>
export default {
  data () {
    return {
      timeoutVar: null
    }
  },
  methods: {
    goBackToFeedback () {
      clearTimeout(this.timeoutVar)
      this.$router.replace('/feedback')
    }
  },
  mounted () {
    const self = this
    this.timeoutVar = setTimeout(function () {
      self.$router.replace('/feedback')
    }, 10000)
  },
  beforeDestroy () {
    clearTimeout(this.timeoutVar)
  }
}
</script>
<style scoped>
</style>